
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {mask} from 'vue-the-mask'
import { useRouter } from "vue-router";
import {active} from "@/core/data/genericData";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "edit-categories-modal", 
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },
  components: {    
    },    
  setup(props) {    
    const active_list_data = active;
    // this.$forceUpdate();
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();

    const category = ref([]);
    const setCategoryData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term" : data}
           if(values.search_term){
          await store.dispatch(Actions.CUST_GET_PARENT_CATEGORY, values).then(({ data }) => {
          category.value = data.page_data;
          console.log(category.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
       }
      } catch (e) {
        console.log(e);
      }

    }

    const company = ref([]);
    const setCompanyData = async (data) => {
        //loading.value = true;
        try {
          var values = { "company_id"  : 0, "page"  : 1, "records_per_page" : 10 }
            await store.dispatch(Actions.CUST_GET_COMPANY_LIST, values).then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            console.log(company.value)
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }
    function onChange(data){
      console.log("CHANGE")
      console.log(data)
    }
    var cat_nm_list : any[] = []
    const cat_id_list: any[] = []
    const category_list = ref([]);
    const setFormData = async (data) => {    
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "brand_id": data.ids, "page": 1, "records_per_page": 1,  "search_term" : "" }
         await store.dispatch(Actions.CUST_GET_PRODUCT_BRAND, values).then(({ data }) => {   
          console.log("DATA") 
          console.log(data)
          category_list.value = (data.category)
          console.log(category_list.value)
          for (var i = 0; i < data.category.length; i++){
              var cat_obj = data.category[i];
              if(cat_obj.active == 1)
              {
                cat_nm_list.push(cat_obj.category_name)
              }
          }

          formData.value = {
            id : data.brand_id,
            name: data.brand_name,
            short_name: data.brand_short_name,
            company_select: data.company_id,
            category_select: cat_nm_list,
            active : data.active,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }


    onMounted( async () => {      
      await setFormData(props.data);
      await setCategoryData("")
      await setCompanyData(company.value)
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editBrandModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const rules = ref({
     name: [
        {
          required: true,
          message: "Brand name is required",
          trigger: "change",  
             
        },  
      ],   
    });

    var cat: any[]=[]
    const setBrandData = async (data) => { 
      console.log(category_list)
      for (var i = 0; i < data.category_select.length; i++){
        if (typeof(data.category_select[i]) == 'number')
        {
          cat.push(data.category_select[i])
        }
        for (var j = 0; j < category_list.value.length; j++){
          if (data.category_select[i] == category_list.value[j]['category_name'] && typeof(data.category_select[i])=="string")
          {
            cat.push(category_list.value[j]['category_id'])
          }
        }
      }

      var user = JSON.parse(JwtService.getToken());       
      const db_data = {
            "brand_id": data.id,
            "brand_name": data.name,
            "brand_short_name": data.short_name,
            "company_id": data.company_select,
            "category_id": cat,
            "user_id": user.user_id,  
            "active": data.active,
        }

        await store.dispatch(Actions.CUST_UPDATE_PRODUCT_BRAND, db_data).then(({ data }) => {
        console.log(data);
        
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product Brand has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editBrandModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setBrandData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setCompanyData,
      setCategoryData,
      onChange,
      company,
      category,
      category_list,
      editBrandModalRef,
      active_list_data,
    };
  },
});
