
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditProductBrandModal from "@/components/modals/forms/EditProductBrandModal.vue";
export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-product-brand-details",
  components: {
   EditProductBrandModal,
    
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewBrand {
      brand_id,    
      brand_name,
      brand_short_name,
      company_name,
      category,
      active : {
        label :string;
        color :string;
      }
    };   
  
    var resultsView = ref<ViewBrand>();
    const loadingData = ref<boolean>(true);
    const count = ref(0);

    resultsView.value = {
       brand_id :"",    
       brand_name : "",
       brand_short_name: "",
       company_name : "",
       category:"",
       active : {
        label :"",
        color :"",
      }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

     const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
     
    }
    var category_list : any[] = []
    const getBrandList = async () => {
     
      try {

    var values = { "brand_id"  : props.id, "page"  : 1, "records_per_page" : 1 , "search_term" : ""}

      await store.dispatch(Actions.CUST_GET_PRODUCT_BRAND, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""
        //for (let j = 0; j < data.result_list.length; j++) {
        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }

        for (var i = 0; i < data.category.length; i++){
          var cat_obj = data.category[i];
          if(cat_obj.active==1){
            category_list.push(cat_obj.category_name)
          }
        }

        resultsView.value = {
          brand_id : data.brand_id,
          brand_name: data.brand_name,
          brand_short_name: data.brand_short_name,
          company_name: data.company_name,
          category: category_list,
          active: {
            label: active_label,
            color: active_color
          },
        }

      console.log(resultsView)
          

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    onMounted( async () => {
      await getBrandList();
      setCurrentPageBreadcrumbs("Product Brand Details", ["Product"]);
    });     


    return {
      changePageChange,
      refreshData,
      resultsView,
      loadingData,
      ids,
    };

  }
});
